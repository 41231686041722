<script setup>
    import {ref,computed,onMounted,onBeforeUnmount,watch} from 'vue';
    import Number from 'o365.modules.utils.number.js';
    const props = defineProps({
        fileUpload: {
            required: true
        },
        progress:{
            required: true
        },
        importData: {
            required: false,
            type: Boolean,
            default: false
        }
    });
    const modal = ref("null");



    defineExpose({
        modal
    })




</script>
<template>

    <OModal ref="modal">
        <div class="modal-dialog">
            <div class="modal-content ">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{$t("Uploading")}}
                        <span v-if="progress.files.size === 1"> {{$t("file")}}</span>
                        <span v-if="progress.files.size > 1"> {{progress.files.size}} {{$t("files")}}</span>...</h5>
                  
                </div>
                <div class="modal-body">
                     <div class="alert alert-danger" v-if="progress.error"  role="alert">
                        
              
                       
                        <p class="text-truncate mb-0" style="max-length:300px;">{{progress.error}}</p>
                      
                    </div>
                    <div v-if="!importData">
                        <div class="progress" v-if="!progress.error">
                            <div class="progress-bar" role="progressbar" :style="{width:progress.progress+'%'}" aria-valuenow="progress.progress" aria-valuemin="0" aria-valuemax="100">{{progress.progress}}%</div>
                        </div>
                        <div class="text-center">
                            <small>{{progress.message}} ({{Number.formatFileSize(progress.uploaded)}} {{$t('of')}} {{Number.formatFileSize(progress.total)}})</small>
                        </div>
                       <!-- <div class="text-center" v-if="progress.speed">
                            <small>{{Number.formatFileSize(progress.speed)}}/s)</small>
                        </div>-->
                    </div>
                    <div v-if="importData" class="d-flex align-items-center">
                        <strong role="status">Loading...</strong>
                        <div class="spinner-border ms-auto" aria-hidden="true"></div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning btn-sm" data-bs-dismiss="modal" @click="fileUpload.cancel()">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </div>
    </OModal>

</template>